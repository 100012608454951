import React from "react";
import Layout from "../components/Layout/Layout";
import { useStaticQuery, graphql } from "gatsby";

const Impressum = () => {
  const {
    page: { html, frontmatter },
  } = useStaticQuery(graphql`
    {
      page: markdownRemark(fields: { slug: { eq: "impressum" } }) {
        frontmatter {
          leftLines
          rightLines
        }
        html
      }
    }
  `);

  return (
    <Layout>
      <div className="impressum">
        <h1>Impressum</h1>
        <main>
          <div>
            {frontmatter.leftLines.map((line, i) => [ <p>{line==="" ? ( <br/> ) : line }</p> ])}
          </div>
          <div>
            {frontmatter.rightLines.map((line, i) => [ <p>{line==="" ? ( <br/> ) : line }</p> ])}
          </div>
        </main>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  );
};

export default Impressum;
